<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ServicioViewData'
import { addMonths, currentDate } from '@/utils/date'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'codigo',
        'descripcion',
        'puesto_servicio_descripcion',
        'finicio',
        'ffin',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        modificarCuadrante: {
          title: 'Modificar cuadrante',
          visible: true,
          icon: 'cuadrante'
        },
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idpuesto_servicio_servicio
        item.finicio = this.$options.filters.shortDate(item.finicio)
        item.ffin = this.$options.filters.shortDate(item.ffin)
        item.title = `${item.descripcion} (${item.codigo})`
        item.subtitle = this.$options.filters.linebreaksBr(`
          ${item.finicio ? `Inicio: ${item.finicio}` : ''}
          ${item.ffin ? `Fin: ${item.ffin}` : ''}
        `)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.modificarCuadrante.visible = this.hasEditPerm(this.permissions.cuadrante.id)
      this.title = `${this.item.dataset.descripcion} (${this.item.dataset.codigo})`
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectServicio(this, this.routeParams.idpuesto_servicio_servicio)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // vigilantes
      if (this.hasViewPerm(this.permissions.vigilante.id)) {
        const detailVigilantes = this.addDetail(
          'vigilantes', 'Vigilantes', 'Vigilantes asociados al servicio', 'vigilante'
        )
        const [datasetVigilantes] = await Data.selectVigilanteCount(this, this.routeParams.idpuesto_servicio_servicio)
        detailVigilantes.badge = datasetVigilantes.count || 0
      }
      // turnos
      if (this.hasViewPerm(this.permissions.tturnoServicio.id)) {
        const detailTurnos = this.addDetail(
          'turnos', 'Turnos', 'Turnos programados del servicio', 'turno'
        )
        const [datasetTurnos] = await Data.selectTurnoServicioCount(this, this.routeParams.idpuesto_servicio_servicio)
        detailTurnos.badge = datasetTurnos.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'vigilantes') {
        this.$appRouter.push({
          name: 'vigilantes__vigilante-list',
          query: {
            idpuesto_servicio_servicio: this.routeParams.idpuesto_servicio_servicio,
          },
        })
      } else if (data.detail.name === 'turnos') {
        this.$appRouter.push({
          name: 'puestosservicio__turno-servicio-list',
          params: {
            idpuesto_servicio: this.routeParams.idpuesto_servicio,
            idpuesto_servicio_servicio: this.routeParams.idpuesto_servicio_servicio,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.modificarCuadrante) {
        const mesSiguiente = addMonths(currentDate(), 1)
        this.$appRouter.push({
          name: 'cuadrantes__cuadrante-edit',
          params: {
            idpuesto_servicio: this.routeParams.idpuesto_servicio,
            idpuesto_servicio_servicio: this.routeParams.idpuesto_servicio_servicio,
            year: mesSiguiente.getFullYear(),
            month: mesSiguiente.getMonth() + 1
          }
        })
      }
    },
  }
}
</script>
