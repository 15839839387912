import { APIFilter } from '@/utils/api'

export default {
  async selectServicio (Vue, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio_servicio', idservicio)
    const resp = await Vue.$api.call('puestoServicioServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectVigilanteCount (Vue, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio_servicio', idservicio)
    const resp = await Vue.$api.call('vigilante.select', { filter: apiFilter, wrapper: 'count' })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectTurnoServicioCount (Vue, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio_servicio', idservicio)
    const resp = await Vue.$api.call('tturnoPuestoServicio.select', { filter: apiFilter, wrapper: 'count' })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  }
}
